import { AxiosError } from 'axios';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { useAlertStore } from '~~/store/alert';
import { DotNestedKeys } from '~~/utils/typescript';

export const useForm = <Input>(
  submit: () => void,
  defaults: Partial<Input> = {}
) => {
  const alert = useAlertStore();
  const input = ref<Partial<Input>>(cloneDeep(defaults));
  const submitting = ref(false);
  const errors = ref<Partial<Record<DotNestedKeys<Input>, string>>>({});

  const handleSubmit = async () => {
    try {
      submitting.value = true;
      await submit();
    } catch (error) {
      errors.value = get(error, 'response.data.error.errors', {});
      alert.requestError(error as AxiosError);
    } finally {
      submitting.value = false;
    }
  };

  const reset = () => {
    input.value = cloneDeep(defaults);
    errors.value = {};
  };

  return { input, submitting, errors, handleSubmit, reset };
};
